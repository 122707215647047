import React from "react";
import Layout from "../containers/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import SurgeryType from "../components/surgeryType";

export const query = graphql`
  query ProjectTemplateQuery($id: String!) {
    surgeryType: sanitySurgeryType(id: { eq: $id }) {
      id
      link
      riveAnimationPage {
        asset {
          url
        }
      }
      riveAnimation {
        asset {
          url
        }
      }
      title
      title_en
      fullDesc_en {
        children {
          text
          marks
          _type
          _key
        }
        list
        style
      }
      photos {
        alt
        alt_en
        asset {
          gatsbyImageData(
            width: 450
            placeholder: BLURRED
          )
        }
      }
      desc_en {
        children {
          text
          marks
          _type
        }
      }
      pageDesc_en {
        children {
          text
          marks
          _type
        }
      }
      _rawPageDesc
      _rawFullDesc
      _rawDesc
      _rawPageDescEn
      _rawFullDescEn
      _rawDescEn
    }
  }
`;

const surgeryType = props => {
  const { data } = props;
  let surgery = data && data.surgeryType;
  let isEn = false
  if(typeof window !== 'undefined') {
    isEn = window.location.href.includes('/en/')
  }
  return (
    <Layout isEn={isEn}>
      <SEO title={ isEn ? surgery.title_en : surgery.title || "Untitled"} />
      <SurgeryType {...surgery} isEn={isEn} />
    </Layout>
  );
};

export default surgeryType;
