import React, { useState } from "react";
import PortableText from "@sanity/block-content-to-react"
import { useRive, Layout, Fit, Alignment } from 'rive-react'

import { GatsbyImage } from 'gatsby-plugin-image'
import SurgeryTypeText from "./surgeryTypeText";

import * as styles from "./surgeryType.module.css"
import * as sharedStyles from "../styles/shared.module.css";

const SurgeryType = props => {
  const { isEn } = props;
  const [isFullTextOpen, setFullTextOpen] = useState(false)
  const toggleFullText = () => setFullTextOpen(!isFullTextOpen)
  let RiveComponent, RiveComponentDecor, rive

  if(props.riveAnimationPage?.asset?.url) {
    const riveDecorStuff = useRive({
      src: props.riveAnimationPage.asset.url,
      autoplay: true,
      layout: new Layout({ fit: Fit.Contain, alignment: Alignment.Center }),
    })

    const riveStuff = useRive({
      src: props.riveAnimation.asset.url,
      autoplay: true,
      layout: new Layout({ fit: Fit.Contain, alignment: Alignment.Center }),
    })

    RiveComponent = riveStuff.RiveComponent
    RiveComponentDecor = riveDecorStuff.RiveComponent
    rive = riveDecorStuff.rive
  }

  return <section className={styles.main} style={{ overflow: isFullTextOpen ? 'hidden' : 'auto' }}>
    <h1 className={styles.heading}>{ isEn ? props.title_en : props.title }</h1>
      <header className={styles.header}>
        <div className={styles.headerLeft}>
          <PortableText
            blocks={isEn ? props._rawPageDescEn : props._rawPageDesc}
          />
        </div>
        <div className={styles.headerRight}>
          <RiveComponentDecor />
        </div>
        <div className={styles.headerRightImage}>
          <RiveComponent />
        </div>
      </header>
      <button onClick={toggleFullText}>{ isEn ? 'Learn more' : 'Узнать больше'}</button>
      <br />
      <h2 className={sharedStyles.sectionTitle}>{ isEn ? 'Works' : 'Работы'}</h2>
      <div className={styles.photos}>
        {
          props.photos?.length ? props.photos.map((photo, i) => {
            const { asset, alt } = photo
            return <GatsbyImage key={i} placeholder="blurred" className={styles.image} objectFit="cover" image={asset.gatsbyImageData} alt={alt} />
          }) : <h3 style={{ paddingBottom: 27 }}>{ isEn ? 'Coming soon' : 'Примеры работ скоро появятся' }</h3>
        }
      </div>
      <SurgeryTypeText isOpen={isFullTextOpen} text={isEn ? props._rawFullDescEn : props._rawFullDesc} toggle={toggleFullText} />
    </section>
  }
  
  
  
  export default SurgeryType